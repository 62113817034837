.react-datepicker-wrapper {
  display: block;
}

.form-control-checkbox {
  display: inline-block;
  margin: auto 2rem;
}

html[dir="rtl"] .react-datepicker__month-container {
  direction: rtl;
}

html[dir="rtl"] .react-datepicker-popper {
  direction: ltr;
}

html[dir="rtl"] .react-datepicker__navigation--previous {
  left: auto;
  right: -2px;

  .react-datepicker__navigation-icon {
    left: auto;
    right: 2px;

    &::before {
      transform: rotate(45deg)
    }
  }
}

html[dir="rtl"] .react-datepicker__navigation--next {
  left: -2px;
  right: auto;

  .react-datepicker__navigation-icon {
    left: auto;
    right: -2px;

    &::before {
      transform: rotate(225deg)
    }
  }
}

.react-datepicker__year-dropdown-container--select, .react-datepicker__month-dropdown-container--select, .react-datepicker__month-year-dropdown-container--select, .react-datepicker__year-dropdown-container--scroll, .react-datepicker__month-dropdown-container--scroll, .react-datepicker__month-year-dropdown-container--scroll {
  margin-right: 16px;
}

.react-datepicker__year-read-view--down-arrow, .react-datepicker__month-read-view--down-arrow, .react-datepicker__month-year-read-view--down-arrow {
  right: auto;
  left: -16px;
}
.react-datepicker__navigation--years {
  background: none;
  line-height: 1.7rem;
  text-align: center;
  cursor: pointer;
  padding: 0;
  border: 0.45rem solid transparent;
  z-index: 1;
  height: 10px;
  width: 10px;
  text-indent: -999em;
  overflow: hidden;
  position: relative;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.react-datepicker__navigation--years.react-datepicker__navigation--years-upcoming {
  top: -4px;
  border-bottom-color: #ccc;
}

.react-datepicker__navigation--years.react-datepicker__navigation--years-previous {
  top: 4px;
  border-top-color: #ccc;
}
