.signature_pad {
    display: flex;
    flex-direction: column;
    height: 65vh;
    max-width: 500px;
    max-height: 250px;
    position: relative;
}
.signature_pad .pad {
    position: relative;
    display: flex;
    width: 100%;
    height: 100%;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border: 1px dotted;
    background: url('./signaturepad.png') no-repeat;
    background-size: cover;
}

.signature_pad .pad canvas {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.02) inset;
}
.signature_pad .controllers {
    position: absolute;
    right: 0;
    top: 0;
    margin: 12px 12px 0 0;
}

.signature_pad .controllers button {
    border-radius: 50%;
    margin-left: 5px;
}