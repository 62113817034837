// Override default variables before the import
$body-bg: transparent;
$popover-max-width: 500px;

// Import Bootstrap and its default variables
@import '~bootstrap/scss/bootstrap.scss';

Form strong.required {
  color: red;
}

Form > .row + .row {
  margin-top: 1rem;
}

.alert {
  margin-top: 2rem;
}
